/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-location-upload',
  template: `
  <div>
    <label for="file-upload" class="custom-file-upload">
      Location select
    </label>
    <input type="file"
      id="file-upload"
      (change)="handleFileInput($event.target.files)"
    />
  </div>
  `,
  styles: [`
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }
  `]
})
export class LocationComponent {
  @Input() selected: string = '';
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;


  @Input() onStopEditing = new Observable();
  @Input() onEdited = new Observable();
  @Input() onClick = new Observable();

  fileToUpload: File | null = null;

  constructor() {}

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
}