import { Injectable } from '@angular/core';
import {
    CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
    Router, UrlSegment, CanLoad,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})

export class AuthGuard implements CanActivate, CanLoad {

    constructor(public authService: AuthService, public router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn !== true) {
            this.router.navigate(['/auth/login']);
        }
        return true;
    }

    canLoad(
        route: import('@angular/router').Route,
        segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
        if (this.authService.isLoggedIn) {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return false;
    }
}
