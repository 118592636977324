<nb-card>
  <nb-card-header>Select which sets the organisation can use</nb-card-header>
  <nb-card-body *ngFor="let brand of mergedSetsToBranches">
    <div>
      <label>Brand: {{brand.name}}</label>
    </div>
    <nb-card-body *ngFor="let set of brand.sets">
      <nb-checkbox (checkedChange)="toggle($event, set)" [checked]="set.checked">{{set.name}}</nb-checkbox>
    </nb-card-body>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="save()">Update</button>
  </nb-card-footer>
</nb-card>
