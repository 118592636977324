import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, tap, retryWhen, of, mergeMap, timer, throwError } from "rxjs";

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(JSON.parse(localStorage.getItem('user'))) {
      const authToken = JSON.parse(localStorage.getItem('user')).token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`
        }
      });
    }
    return next.handle(request)
      .pipe(
        this.retryAfterDelay(request, next),
      );
  }

  retryAfterDelay(request, next): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          if (count === retryCount) {
            this.ngxSpinnerService.hide();
            if(err.status === 401) {
              this.router.navigate(['/auth/login']);
            }
            return throwError({
              message: 'counter'
            });
          }
          if (err.status === 422) {
            return throwError(err);
          }
          return of(err).pipe(
            tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
            mergeMap(() => timer(retryWaitMilliSeconds))
          );
        })
      );
    });
  }
}