import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { MapDialogComponent } from './showcase-dialog/showcase-dialog.component';
import { DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'ngx-dialog',
  template: `
    <button nbButton (click)="open()">Open map</button>
  `,
  styles: [`
    button {
      text-transform: capitalize!important;
      width: 100%;
    }
  `],
})
export class MapControlDialogComponent extends DefaultEditor implements OnInit {
  @Input() selected: string[] = [];
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  brands = [];
  sets = [];
  checked = {};

  constructor(private dialogService: NbDialogService) {
    super();
  }

  ngOnInit() {
    console.log('this: ', this.cell);
  }

  open() {
    let data = (this.cell as any).row.data || {};
    this.dialogService.open(MapDialogComponent, {
      context: {
        address: data.address,
        lat: data.lat,
        lang: data.lang,
        onSave: this.onSave.bind(this)
      }
    });
  }

  onSave(data) {
    this.cell.newValue = data;
  }
}
