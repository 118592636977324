import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'ngx-login',
    templateUrl: './login.component.html',
})
export class NgxLoginComponent {

    constructor(private authService: AuthService) {

    }

    login(value) {
        this.authService.signIn(value.email, value.password);
    }
}
