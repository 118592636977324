import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { GalleryDialogComponent } from './showcase-dialog/showcase-dialog.component';
import { DefaultEditor } from 'ng2-smart-table';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-dialog',
  template: `
    <button nbButton (click)="open()">Gallery</button>
  `,
  styles: [`
    button {
      text-transform: capitalize!important;
      width: 100%;
    }
  `],
})
export class GalleryControllDialogComponent extends DefaultEditor {
  @Input() selected: string[] = [];
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService: NbDialogService) {
    super();
  }

  open() {
    this.dialogService.open(GalleryDialogComponent, {
      context: {
        // environment.host + '/' + this.rowData.map.path
        images: this.rowData 
          ? this.rowData.gallery 
          : this.cell 
            ? this.cell.newValue || []
            : [],
        onSave: this.onSave.bind(this),
        isEdit: !!this.cell
      },
    });
  }

  onSave(e) {
    this.cell.newValue = [
      ...e
    ];
  }
}
