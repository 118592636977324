<nb-card>
  <nb-card-header>
    <div class="header">Select the location</div>
    <div class="right">
      <button nbButton (click)="save()">Save</button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <google-map #googleMap (mapClick)="mapClick($event)" [center]="searchedPosition" [zoom]="zoom" width="87vw" height="80vh">
      <map-marker [position]="searchedPosition"></map-marker>
    </google-map>
  </nb-card-body>
</nb-card>
<div id="content" class="hidden">
  <span class="dot"></span>
</div>
