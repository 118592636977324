import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class HandleBrandsDialogComponent implements OnInit {

  @Input() brands: [];
  @Input() sets: [];
  @Input() handleSets: [];
  @Input() onSave: (e) => void;

  mergedSetsToBranches = [];
  toggled = {};

  constructor(protected ref: NbDialogRef<HandleBrandsDialogComponent>) {}

  ngOnInit() {
    this.mergedSetsToBranches = this.brands.map((e: any) => {
      return {
        ...e,
        sets: this.sets.filter((s: any) => {
          return s.brand.id === e.id;
        }).map((s: any) => {
          return {
            ...s,
            checked: this.handleSets.includes(s.id as never)
          }
        })
      }
    });
    this.handleSets.forEach((element: any) => {
      this.toggled[element] = true;
    });
  }

  save() {
    this.onSave(this.toggled);
    this.ref.close();
  }

  toggle(checked: boolean, set) {
    this.toggled[set.id] = checked;
  }
}
