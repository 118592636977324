<nb-card>
  <nb-card-header>
    <div class="header">Gallery</div>
    <div *ngIf="isEdit" class="right">
      <label nbButton class="custom-file-upload">
        <input
          (change)="handleFileInput($event)"
          accept=".jpg, .jpeg, .png"
          type="file"
        />
        Upload
    </label>
    </div>
  </nb-card-header>
  <nb-card-body>
    <gallery
      [thumbTemplate]="thumbTemplate"
    ></gallery>
    <ng-template #thumbTemplate let-type="type">
      <div *ngIf="isEdit" (click)="delete($event)" class="de-icon">
        &#10005;
      </div>
    </ng-template>
  </nb-card-body>
  <nb-card-footer>
    <button *ngIf="isEdit" nbButton hero status="primary" (click)="save()">Save</button>
    <button *ngIf="!isEdit" nbButton hero status="primary" (click)="close()">Close</button>
  </nb-card-footer>
</nb-card>
