import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { GalleryComponent } from 'ng-gallery';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class GalleryDialogComponent implements AfterViewInit {
  @ViewChild(GalleryComponent) gallery: GalleryComponent;

  @Input() images: string[] = [];
  @Input() onSave: (e) => void;
  @Input() isEdit = false;

  uploadedFiles = [];
  filesToRemove = [];
  allFilesPerIndex = [];

  constructor(protected ref: NbDialogRef<GalleryDialogComponent>) {}

  ngAfterViewInit() {
    this.images.map((e: any) => {
      const path =  e.path 
        ? environment.host + '/' + e.path.path
        : URL.createObjectURL(e);
      return this.gallery.addImage({ src: path, thumb: path })
    });
    this.allFilesPerIndex = this.images.map((i: any) => {
      return {
        ...i,
        active: true
      }
    });

    // set IDS to know what item we delete on X
    setTimeout(() => {
      this.updateIndexes();
    }, 50)
  }

  updateIndexes() {
    const collection = document.getElementsByClassName('de-icon');
    for(let counter = 0; counter < collection.length; counter++) {
      collection[counter].id = 'element_' + counter;
    }
  }

  save() {
    this.onSave(this.allFilesPerIndex);
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

  delete(event) {
    const index = +event.target.id.split('_')[1];
    let counter = 0;
    for(let i = 0; i < this.allFilesPerIndex.length; i++) {
      if(this.allFilesPerIndex[i].active) {
        if(index === counter) {
          this.allFilesPerIndex[i].active = false;
          counter++;
        } else {
          counter++;
        }
      }
    }
    this.gallery.remove(index);

    // set IDS to know what item we delete on X
    setTimeout(() => {
      this.updateIndexes();
    }, 50)
  }

  handleFileInput(event: any) {
    this.uploadedFiles.push(event.target.files.item(0));
    const url = URL.createObjectURL(event.target.files.item(0));
    this.gallery.addImage({ src: url, thumb: url });
    this.allFilesPerIndex.push({
      file: event.target.files.item(0),
      active: true
    });

    // set IDS to know what item we delete on X
    setTimeout(() => {
      this.updateIndexes();
    }, 50)
  }
}
