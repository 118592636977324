import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-custom-empty',
  template: `<div></div>`,
})
export class CustomEmptyComponent {
  @Input() selected: string = '';
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;


  @Input() onStopEditing = new Observable();
  @Input() onEdited = new Observable();
  @Input() onClick = new Observable();

  renderValue = '';
}
