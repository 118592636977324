import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ngx-set-password',
    templateUrl: './set-password.component.html',
})
export class NgxSetPasswordComponent implements OnInit {
    public id: string;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute
    ) {}
    
    ngOnInit() {
       this.id = this.route.snapshot.paramMap.get('id');
    }

    async login(value) {
        if(value.password !== value.passwordrepeat) {
            Swal.fire(
              'Failed!',
              'Your passwords doesnt match one another',
              'error'
            );
            return;
        }
        if(value.password.length < 6) {
            Swal.fire(
              'Failed!',
              'Password is too short...',
              'error'
            );
            return;
        }
        const res = await this.authService.setPassword(value.password, this.id);
        if(res) {
            if(res.error.errors.hash === 'alreadySetup') {
                Swal.fire(
                  'Failed!',
                  'Your account is already enrolled',
                  'error'
                );
                return;
            }
            if(res.error.errors.hash === 'notFound') {
                Swal.fire(
                  'Failed!',
                  'This account doesnt exists',
                  'error'
                );
                return;
            }
            Swal.fire(
                'Failed!',
                'There is a problem with our server. Try again later.',
                'error'
            );
            return;
        }
    }
}
