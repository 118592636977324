import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { HandleBrandsDialogComponent } from './showcase-dialog/showcase-dialog.component';
import { DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'ngx-dialog',
  template: `
    <button nbButton (click)="open()">Check handles</button>
  `,
  styles: [`
    button {
      text-transform: capitalize!important;
      width: 100%;
    }
  `],
})
export class HandlesDialogComponent extends DefaultEditor implements OnInit {
  @Input() selected: string[] = [];
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  brands = [];
  sets = [];
  checked = {};

  constructor(private dialogService: NbDialogService) {
    super();
  }

  ngOnInit() {
    console.log('this: ', this.cell);
  }

  open() {
    let data = (this.cell as any).row.data.data;
    if((this.cell as any).row.index === -1) {
      data = (this.cell as any).dataSet.rows[0].data.data
    }
    this.dialogService.open(HandleBrandsDialogComponent, {
      context: {
        brands: data.brands,
        sets: data.sets,
        handleSets: this.cell.newValue,
        onSave: this.onSave.bind(this)
      },
    });
  }

  onSave(data) {
    this.checked = data;
    this.cell.newValue = Object.keys(data).filter((o) => data[o]);
  }
}
