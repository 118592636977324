import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { PositionModel } from '../../search-map/entity/position.model';
import { GoogleMap } from '@angular/google-maps';


class Popup extends google.maps.OverlayView {
  position: google.maps.LatLng;
  containerDiv: HTMLDivElement;

  constructor(position: google.maps.LatLng, content: HTMLElement) {
    super();
    this.position = position;

    content.classList.add("popup-bubble");

    // This zero-height div is positioned at the bottom of the bubble.
    const bubbleAnchor = document.createElement("div");

    bubbleAnchor.classList.add("popup-bubble-anchor");
    bubbleAnchor.appendChild(content);

    // This zero-height div is positioned at the bottom of the tip.
    this.containerDiv = document.createElement("div");
    this.containerDiv.classList.add("popup-container");
    this.containerDiv.appendChild(bubbleAnchor);

    // Optionally stop clicks, etc., from bubbling up to the map.
    Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    this.getPanes()!.floatPane.appendChild(this.containerDiv);
  }

  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.containerDiv.parentElement) {
      this.containerDiv.parentElement.removeChild(this.containerDiv);
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    )!;

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? "block"
        : "none";

    if (display === "block") {
      this.containerDiv.style.left = divPosition.x + "px";
      this.containerDiv.style.top = divPosition.y + "px";
    }

    if (this.containerDiv.style.display !== display) {
      this.containerDiv.style.display = display;
    }
  }
}

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class MapDialogComponent implements OnInit {
  @ViewChild(GoogleMap) googleMap: GoogleMap;

  @Input() address: string;
  @Input() lat: number;
  @Input() lang: number;
  @Input() onSave: (e) => void;

  infoWindow = new google.maps.InfoWindow({
    content: "Click the map to get Lat/Lng!",
    // Walltopia
    position: { lat: 42.6655467, lng: 23.3735451 },
  });
  zoom = 16;
  popup = null;
  clickedLat = null;
  clickedLang = null;
  searchedPosition: PositionModel = new PositionModel();

  constructor(protected ref: NbDialogRef<MapDialogComponent>) {}

  ngOnInit() {
    if(this.lat) {
      this.searchedPosition = new PositionModel(
        +this.lat,
        +this.lang,
      );
      return;
    }
    // set up current location
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.searchedPosition = new PositionModel(
          position.coords.latitude,
          position.coords.longitude,
        );
      });
    }
  }

  mapClick(mapsMouseEvent) {
    this.popup = new Popup(
      mapsMouseEvent.latLng,
      document.getElementById("content") as HTMLElement
    );
    this.popup.setMap(this.googleMap.googleMap);
    this.clickedLat = mapsMouseEvent.latLng.lat();
    this.clickedLang = mapsMouseEvent.latLng.lng();

  }

  setPosition(position: PositionModel) {
    this.searchedPosition = position;
  }

  save() {
    this.onSave({
      lat: this.clickedLat || this.lat,
      lang: this.clickedLang || this.lang
    });
    this.ref.close();
  }
}
