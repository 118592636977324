import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: 'showcase-dialog.component.html',
  styleUrls: ['showcase-dialog.component.scss'],
})
export class SvgPreviewDialogComponent {

  @Input() svg: string;

  constructor(protected ref: NbDialogRef<SvgPreviewDialogComponent>) {}

  save() {
    this.ref.close();
  }
}
