/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-custom-multiselect',
  template: `
  <nb-select [multiple]="multi" (selectedChange)="change($event)" [(selected)]="checked" placeholder="Select">
    <nb-option *ngIf="!data.length" value="loading">Loading...</nb-option>
    <nb-option *ngFor="let item of data" [value]="item.value"> {{ item.name }}</nb-option>
  </nb-select>
  `,
  styles: [`
  nb-select {
    width: 100%;
  }
  `]
})
export class CustomMultiselectComponent extends DefaultEditor implements OnInit {
  @Input() selected: string[] = [];
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue = '';
  data = [];
  checked = "";
  multi = false;

  ngOnInit() {
    this.data = (this as any).cell.row.data.data || (this.cell as any).column.settings.data.map(d => {
      return {
        name: d.name,
        value: d.id
      }
    });
    this.multi = (this as any).cell.row.data.multi;
    this.checked = (this as any).cell.row.data.checked;
  }

  change(e) {
    this.cell.newValue = e;
  }
}
