<nb-card>
  <nb-card-header>Previev the gym map</nb-card-header>
  <nb-card-body>
    <img
      [src]="svg"
      alt="map"
      height="87"
      width="100"
    />
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="save()">Close</button>
  </nb-card-footer>
</nb-card>
