<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>


<nb-card>
    <nb-card-body>
        <form  #loginForm="ngForm" (ngSubmit)="login(loginForm.value)">
            <div class="form-group">
                <label for="exampleInputEmail1" class="label">Email address</label>
                <input type="email" nbInput fullWidth id="exampleInputEmail1" placeholder="Email" ngModel name="email">
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1" class="label">Password</label>
                <input type="password" nbInput fullWidth id="exampleInputPassword1" placeholder="Password" ngModel
                    name="password">
            </div>

            <button type="submit" fullWidth nbButton status="danger">Submit</button>
        </form>
    </nb-card-body>
</nb-card>