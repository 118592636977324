<h1 id="title" class="title">Set your password</h1>
<p class="sub-title">Hello! It is time to set your password, that you will use into our system.</p>


<nb-card>
    <nb-card-body>
        <form  #loginForm="ngForm" (ngSubmit)="login(loginForm.value)">
            <div class="form-group">
                <label for="exampleInputPassword1" class="label">Password</label>
                <input type="password" nbInput fullWidth placeholder="Password" ngModel
                    name="password" style="margin-bottom: 20px">
                <label for="exampleInputPassword1" class="label">Confirm Password</label>
                <input type="password" nbInput fullWidth placeholder="Password" ngModel
                    name="passwordrepeat">
            </div>

            <button type="submit" fullWidth nbButton status="danger">Submit</button>
        </form>
    </nb-card-body>
</nb-card>