import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { SvgPreviewDialogComponent } from './showcase-dialog/showcase-dialog.component';
import { DefaultEditor } from 'ng2-smart-table';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-dialog',
  template: `
    <button *ngIf="rowData.map" nbButton (click)="open()">Preview map</button>
    <span *ngIf="!rowData.map">None</span>
  `,
  styles: [`
    button {
      text-transform: capitalize!important;
      width: 100%;
    }
  `],
})
export class SvgPreviewControllDialogComponent extends DefaultEditor {
  @Input() selected: string[] = [];
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService: NbDialogService) {
    super();
  }

  open() {
    this.dialogService.open(SvgPreviewDialogComponent, {
      context: {
        svg: environment.host + '/' + this.rowData.map.path,
      },
    });
  }
}
