/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-custom-select',
  template: `
  <nb-checkbox (change)="change($event)" [checked]="selected"></nb-checkbox>
  `,
})
export class CustomCheckboxComponent extends DefaultEditor implements OnInit {
  @Input() selected: string = '';
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  renderValue = '';

  ngOnInit() {
    const cell = (this.cell as any);
    this.selected = cell.row.data[cell.column.id]
  }

  change(e) {
    this.onChange(e.target.checked);
    this.cell.newValue = e.target.checked;
  }
}
